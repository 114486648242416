import { useTheme } from '@emotion/react'
import { Button, Card, CardMedia, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import plus from "../../../assests/images/plus.png";
import minus from "../../../assests/images/minus.png";
import useScreenSize from '../../../utils/ScreenWidthHight';
import heartIcon from "../../../assests/images/wishlistInActive.png"
import heartIconFill from "../../../assests/images/wishlistActive.png"
import { cartProductCreateAction, cartProductDeleteAction, cartUpdateAction, productCheckCartAction, productWishCheckAction, productWishCreateAction, productWishDeleteAction, retailProductCheckCartAction } from '../../../store/slices/retail/ProductDetails/ProductDetailsActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../Context';
import { cartRefresh, refreshIdUpdate } from '../../../store/slices/CommonSlice';

const ProductPartnerCard = ({ details, image, brandName, partnerSellingPrice, onClick, handleAddCart, formData, Availability_Stock }) => {
    const theme = useTheme()
    const [cart, setCart] = useState(0);
    const [Quantity, setQuantity] = useState({ User_Cart_Id: '', Quantity: 0, Availability_Stock: 0 })
    const [wishState, setWishState] = useState(false)
    const [wishIdHandle, setWishIdHandle] = useState("")
    const auth = useSelector(state => state.auth)
    const winSize = useScreenSize();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { toolTipActive } = React.useContext(GlobalContext);
    const partnerDataFromDetails = formData
    useEffect(() => {
        if (auth?.isAuthenticated) {
            wishCheckActionCall({

                "User_Email_Id": auth?.user?.Email_Id,
                "Product_Id": details.Product_Id,
                "Partner_Details_Id": details.Partner_Details_Id

            })
        }

    }, [])

    useEffect(() => {
        if (auth?.isAuthenticated) {
            productCheckCartActionCall({
                "User_Email_Id": auth?.user?.Email_Id,
                "Partner_Product_ID": formData.Partner_Product_Id,
                "Size": formData.Size
            })
        }
    }, [])

    const productCheckCartActionCall = async (formData) => {
        const response = await retailProductCheckCartAction(formData)
        if (response.status) {
            let data = response.data
            setQuantity(data)
            setCart(response.data?.Quantity || 0)
        }
        else {
            setQuantity(state => ({ ...state, Availability_Stock: Number(Availability_Stock) || 0 }))
            setCart(0)
        }
    }

    const cartUpdateActionCall = async (formData, type) => {
        const response = await cartUpdateAction(formData)
        if (response.status) {
            if (type === "INC") {
                setCart((prevSize) => prevSize + 1);
                toolTipActive("success", "Quantity Increased");
                productCheckCartActionCall({
                    "User_Email_Id": auth?.user?.Email_Id,
                    "Partner_Product_ID": partnerDataFromDetails.Partner_Product_Id,
                    "Size": partnerDataFromDetails.Size
                })
            }
            else {
                setCart(cart - 1)
                toolTipActive("success", "Quantity Decreased");
                productCheckCartActionCall({
                    "User_Email_Id": auth?.user?.Email_Id,
                    "Partner_Product_ID": partnerDataFromDetails.Partner_Product_Id,
                    "Size": partnerDataFromDetails.Size
                })
            }
        }
        else {
        }
    }
    const cartProductDeleteActionCall = async (formData) => {
        const response = await cartProductDeleteAction(formData)
        if (response?.status) {
            setCart(cart - 1)
        } else {
        }
    }

    const wishCheckActionCall = async (formData) => {
        const response = await productWishCheckAction(formData)
        if (response.status) {
            if (response.data) {
                setWishState(response.data?.length > 0 ? true : false)
                if (response.data?.length > 0)
                    setWishIdHandle(response.data[0].Wish_Id)
            }
        }
        else
            setWishState(false)
    }



    return (
        <Box
            sx={{ position: 'relative', minWidth: { xs: winSize.getHalfScreenSize.width - 10, sm: 200, md: 280 }, maxWidth: { xs: winSize.getHalfScreenSize.width - 10, sm: 200, md: 280 }, height: { xs: 200, sm: 280, md: 350 }, border: `1px solid ${theme.palette.input.borderColor}`, borderRadius: 5, display: "flex", flexDirection: "column", gap: { xs: 0, md: 3 }, justifyContent: { xs: "space-around", sm: "center" }, alignItems: "center", overflow: "hidden" }}>

            <IconButton sx={{
                position: 'absolute',
                top: '0%',
                right: '0%',
                width: '35px',
                height: '35px',
                zIndex: 1,
            }}

                onClick={
                    auth?.isAuthenticated ?
                        async () => {
                            const formData = {
                                User_Email_Id: auth?.user?.Email_Id,
                                Product_Id: details.Product_Id,
                                Partner_Details_Id: details.partnerId
                            }
                            if (wishState) {
                                const response = await productWishDeleteAction({ Wish_Id: wishIdHandle })
                                if (response.status) {
                                    setWishState(false)
                                    setWishIdHandle("")
                                    wishCheckActionCall({
                                        "User_Email_Id": auth?.user?.Email_Id,
                                        "Product_Id": details.Product_Id,
                                        "Partner_Details_Id": details.Partner_Details_Id
                                    })
                                    toolTipActive("success", response.data.UI_Display_Message);
                                } else {
                                    toolTipActive("error", "Unable to Remove from wishlist");
                                }
                            }
                            else {
                                const response = await productWishCreateAction(formData)
                                if (response.status) {
                                    setWishState(response.status)
                                    wishCheckActionCall({
                                        "User_Email_Id": auth?.user?.Email_Id,
                                        "Product_Id": details.Product_Id,
                                        "Partner_Details_Id": details.Partner_Details_Id
                                    })
                                    toolTipActive("success", response.data.UI_Display_Message);
                                } else {
                                    toolTipActive("error", "Unable to Add wishlist");
                                }
                            }
                        }
                        :
                        () => {
                            navigate('/auth/signin')
                        }
                }

            >
                <CardMedia
                    image={!wishState ? heartIcon : heartIconFill}
                    alt="Heart Icon"
                    sx={{ width: "100%", height: "100%", }}
                />
            </IconButton>
            <Box sx={{ maxWidth: '100%', ...(winSize.screenSize.width < 600 ? { display: "flex", justifyContent: "center", marginTop: "10px" } : { display: "flex", justifyContent: "center" }) }} onClick={onClick}
            >
                <CardMedia
                    image={image+"?q=10"}
                    sx={{
                        width: { xs: "95%", sm: "95%" },
                        height: {
                            xs: 95,
                            sm: 150,
                            md: 200
                        },
                        objectFit: "fill",
                        borderRadius: 1,
                        cursor: "pointer",
                    }}
                    component="img"
                />


            </Box>

            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", ...(winSize.screenSize.width < 600 ? { rowGap: "5px" } : {}) }} >
                <Box sx={{ width: "90%", }} >{
                    winSize.screenSize.width < 600 ?
                        <Typography sx={{ color: theme.palette.text.primary, overflow: "hidden", whiteSpace: 'nowrap', fontSize: "12px" }}>{brandName}</Typography>
                        :
                        <Typography variant="body1" sx={{ color: theme.palette.text.primary, overflow: "hidden", whiteSpace: 'nowrap' }}>{brandName}</Typography>}
                </Box>
                <Box sx={{ width: { xs: "95%", md: "90%" }, display: 'flex', flexWrap: { xs: "wrap", md: "nowrap" }, justifyContent: "space-between", alignItems: "center" }} >
                    <Typography sx={{ color: theme.palette.text.secondary, width: { xs: "40%", md: "50%" }, fontSize: { xs: "12px", sm: "14px" } }} > &#8377; {partnerSellingPrice}.00</Typography>
                    {cart !== 0 && Quantity?.User_Cart_Id ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "50%",
                                gap: "4px"
                            }}
                        >
                            <IconButton

                                onClick={() => {
                                    if (cart === 1) {
                                        cartProductDeleteActionCall({
                                            User_Cart_Id: Quantity.User_Cart_Id
                                        })
                                    }
                                    else
                                        cartUpdateActionCall({
                                            User_Cart_Id: Quantity.User_Cart_Id,
                                            Quantity: cart - 1,
                                        }, "DEC")
                                }}
                                disabled={(cart === 1 && Number(Quantity?.Availability_Stock) === 0) ? true : false}

                            >
                                <img
                                    src={minus}
                                    alt="Minus Icon"
                                    style={{
                                        width: winSize.screenSize.width > 600 ? "25px" : "14px",
                                        height: "auto",
                                        marginRight: "0px",
                                        cursor: "pointer",
                                        filter: cart === 1 ? "blur(2px)" : "blur(0px)"
                                    }}


                                />
                            </IconButton>

                            <Typography
                                variant="body1"
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    padding: winSize.screenSize.width > 600 ? "3px 7px" : "2px 3px",
                                    borderRadius: "5px",
                                    border: "1px solid",
                                    borderColor: theme.palette.extra.cartBorderColor,

                                }}
                            >
                                {cart}
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    cartUpdateActionCall({
                                        User_Cart_Id: Quantity.User_Cart_Id,
                                        Quantity: cart + 1,
                                    }, "INC")
                                }}
                                disabled={(Number(Quantity?.Availability_Stock) === cart || Number(Quantity?.Availability_Stock) === 0) ? true : false}
                            >
                                <img
                                    src={plus}
                                    alt="Plus Icon"
                                    style={{
                                        width: winSize.screenSize.width > 600 ? "25px" : "14px",
                                        height: "auto",
                                        marginLeft: "0px",
                                        cursor: "pointer",
                                        filter: (Number(Quantity?.Availability_Stock) === cart || Number(Quantity?.Availability_Stock) === 0) ? "blur(2px)" : "blur(0px)"
                                    }}
                                />
                            </IconButton>
                        </Box>
                    ) : (
                        <Button

                            onClick={
                                auth?.isAuthenticated ?
                                    async () => {
                                        const response = await cartProductCreateAction(formData)
                                        if (response.status) {
                                            dispatch(cartRefresh())
                                            toolTipActive("success", "Added to Cart")
                                            productCheckCartActionCall({
                                                "User_Email_Id": auth?.user?.Email_Id,
                                                "Partner_Product_ID": partnerDataFromDetails.Partner_Product_Id,
                                                "Size": partnerDataFromDetails.Size
                                            })
                                            if (wishState) {
                                                const response = await productWishDeleteAction({ Wish_Id: wishIdHandle })
                                                if (response.status) {
                                                    dispatch(refreshIdUpdate(details.Product_Id))
                                                    setWishState(false)
                                                }
                                            }
                                        }
                                    } :
                                    () => {
                                        navigate('/auth/signin')
                                    }
                            }

                            sx={{
                                backgroundColor: theme.palette.extra.primary,
                                color: theme.palette.primary.contrastText,
                                width: { xs: "auto", sm: "80%" },
                                overflow: "hidden",
                                height: "40px",
                                ...(winSize.screenSize.width < 600 ? {
                                    fontSize: "8px",
                                    minWidth: "40%",
                                    letterSpacing: "1px",
                                    maxWidth: "60%",
                                    paddingX: 1,
                                    height: "30px",
                                    borderRadius: "50px"
                                } : {})
                            }}
                            size={winSize.screenSize.width < 600 ? 'small' : "medium"}
                            disabled={Number(Quantity?.Availability_Stock) === 0}
                        >
                            Add to Cart
                        </Button>
                    )}
                </Box>
            </Box>
            {Number(Quantity?.Availability_Stock) < 20 ? <Typography sx={{ color: theme.palette.error.main, ...(winSize.screenSize.width < 600 ? { fontSize: "10px" } : {}) }} > {(Number(Quantity?.Availability_Stock) > 0) ? "!!! Hurry Up" + Number(Quantity?.Availability_Stock) + "Left" : "No Stock Availble" + Availability_Stock}</Typography> : Number(Number(Quantity?.Availability_Stock)) == cart && (
                <Typography sx={{ color: theme.palette.error.main, ...(winSize.screenSize.width < 600 ? { fontSize: "10px" } : {}) }} >Maximum Stock Left {Number(Quantity?.Availability_Stock)} Only</Typography>
            )}
        </Box>
    )
}

export default ProductPartnerCard