import React, { useEffect } from 'react';
import { Grid, Box, CardMedia, Typography, Button, Table, TableBody, TableRow, TableCell, Skeleton } from '@mui/material'
import { useNavigate, useParams } from "react-router-dom";
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { fetchCoupons } from '../../../../store/slices/retail/home/CouponSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import parser from 'html-react-parser'
import { GlobalContext } from '../../../../Context';

const CouponDetails = () => {
    const navigate = useNavigate();
    const winSize = useScreenSize();
    const dispatch = useDispatch();
    const { loading, coupons } = useSelector((state) => state.coupons || []);
    const { id } = useParams()
    const theme = useTheme()
    const path = window.location.pathname
    const { toolTipActive } = React.useContext(GlobalContext);
    const auth = useSelector(state => state.auth)
    
    useEffect(() => {
        if (id) {

            dispatch(fetchCoupons(
                {
                    formData: {
                        Coupon_Id: [id]
                    },
                    Pagination: {}
                }
            ));
        }
        else {
            dispatch(fetchCoupons({}));
        }
    }, [id]);

    return (
        <Box sx={{ width:"100%", minHeight: { sm: "100vh", xs: 'auto' }, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {loading ? (
                <Box sx={{ width: '97%', minHeight: { sm: "100vh", xs: 'auto' }, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <Box sx={{ width: { md: "60%", xs: "100%" }, height: "100%", display: { xs: "none", md: "flex" }, justifyContent: "center", alignItems:'center' }}>
                        <Skeleton variant="rectangular" width="50%" height="50%" />
                    </Box>
                    <Box sx={{ width: { md: "40%", xs: "90%" }, height: "100%" }}>
                        <Skeleton variant="rectangular" width="100%" height={172} />
                        <Skeleton variant="text" width="60%" height={40} />
                        <Skeleton variant="text" width="80%" height={30} />
                        <Table sx={{ borderCollapse: 'collapse' }}>
                            <TableBody>
                                {Array(6).fill(0).map((_, index) => (
                                    <TableRow key={index} sx={{ borderBottom: 'none' }}>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                            <Skeleton variant="text" width="100px" />
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                            <Skeleton variant="text" width="200px" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Skeleton variant="rectangular" width="100%" height={30} sx={{ mt: 2 }} />
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 2 }}>
                            <Skeleton variant="text" width="45%" height={40} />
                            <Skeleton variant="text" width="45%" height={40} />
                        </Box>
                    </Box>
                </Box>
            ) : !coupons || coupons.length === 0 ? (
                <Box sx={{ height: '70vh', justifyContent: "center", display: 'flex', width: "100%", alignItems: "center" }}>
                    No data found
                </Box>
            ) : (
                <Box sx={{ width: "100%", minHeight: { sm: "100vh", xs: 'auto' }, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <Box sx={{ width: { md: "60%", xs: "100%" }, height: "100%", display: { xs: "none", md: "flex" }, justifyContent: "flex-end" }}>
                        <img
                            src={require('../../../../assests/images/couponretail.png')}
                            style={{ width: "100%", height: "100%", scale: "60%", objectFit: "contain" }}
                        />
                    </Box>
                    <Box sx={{ width: { md: "40%", xs: "90%" }, height: "100%" }}>
                        {!loading && coupons?.map((coupon, index) => (
                            <Box key={index} sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", gap: 1 }}>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <img src={coupon?.Image_Path+"?q=10"} style={{ width: "100%", height: 172, objectFit: "contain" }} />
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Typography sx={{ fontSize: { md: '34px', sm: '34px', xs: '20px' }, color: theme.palette.text.primary }}>{coupon?.Code?.toUpperCase()}</Typography>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Typography sx={{ color: theme.palette.text.primary }}>{coupon?.Name}</Typography>
                                </Box>

                                <Table sx={{ border: 'none' }}>
                                    <TableBody>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: '25px' }}>Partner Name</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{coupon?.Partner_Name || "Meepaisa"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: { xs: '68px', md: '83px' } }}>Brand</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <img
                                                            src={coupon?.Brand_Image+"?q=10"}
                                                            alt="Brand"
                                                            style={{
                                                                width: '54px',
                                                                height: '44px',
                                                                borderRadius: '4px',
                                                                objectFit: 'contain'
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: { xs: '53px', md: '62px' } }}>Category</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{coupon?.Category_Name}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: { xs: '56px', md: '65px' } }}>Discount</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>
                                                            {coupon?.Discount_Label?.toUpperCase()} {coupon?.Discount_Type === "A" ? "₹" : null} {coupon?.Discount}{coupon?.Discount_Type === "P" ? "%" : null} off
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: { xs: '50px', md: '57px' } }}>Cashback</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>
                                                            {coupon.Cashback_Label} {coupon.ESI_Cashback_Type === "A" ? "₹" : null} {coupon.ESI_Cashback_Amount} {coupon.esiCashBack} {coupon.ESI_Cashback_Type === "A" ? " " : coupon.ESI_Cashback_Type === "R" ? "Rewards" : '% Off'}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableRow sx={{ borderBottom: 'none' }}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' }, mr: '30px' }}>Limited Count </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: 0 }}>
                                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>{coupon?.Coupon_Pending_Count}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Grid>
                                        </Grid>
                                    </TableBody>
                                </Table>

                                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 3.6, alignItems: "center", mt: '20px' }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' } }}> Start Date : </Typography>
                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}> {coupon?.Valid_From_Date}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <Typography sx={{ fontSize: { md: '16px', sm: '16px', xs: '12px' } }}>Expires on : </Typography>
                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: "bold", fontSize: { md: '16px', sm: '16px', xs: '12px' } }}> {coupon?.Valid_Through_Date}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ maxWidth: { xs: "100%", md: "80%" }, border: `1px solid ${theme.palette.text.secondary}`, padding: "5px 7px", borderRadius: 3 }}>
                                    <Box sx={{ width: "100%" }}>
                                        {parser(coupon?.Description ? coupon?.Description : " h")}
                                    </Box>
                                </Box>
                                <Box sx={{ width: { xs: "100%", md: "80%" }, display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                    {auth.isAuthenticated ? (
                                        <Button
                                            variant="contained"
                                            sx={{ padding: "0 0px 0px 10px", borderRadius: 2 }}
                                            endIcon={
                                                <Box sx={{ backgroundColor: theme.palette.extra.couponCode, padding: "1px 5px", borderRadius: 2 }}>
                                                    <ContentCopyIcon sx={{ transform: "translate(0%,25%)" }} />
                                                </Box>
                                            }
                                            onClick={async () => {
                                                try {
                                                    await navigator.clipboard.writeText(coupon?.Code);
                                                    toolTipActive("success", 'Coupon Code Copied.');
                                                } catch (err) {
                                                    toolTipActive("error", 'Failed to copy coupon code.');
                                                }
                                            }}
                                        >
                                            {coupon?.Code}
                                        </Button>
                                    ) : null}
                                    <Button
                                        variant="contained"
                                        sx={{ padding: "auto .5px", width: auth.isAuthenticated ? 'auto' : '100%' }}
                                        onClick={() => {
                                            if (path.includes("retail"))
                                                navigate(`/retail/products?Partner_Details_Id=${coupon?.Partner_Details_Id}&Category_Id=${coupon.Category_Id}&Brand_Id=${coupon.Brand_Id}`)
                                            else
                                                navigate(`/grocery/products?Partner_Details_Id=${coupon?.Partner_Details_Id}&Category_Id=${coupon.Category_Id}&Brand_Id=${coupon.Brand_Id}`)
                                        }}
                                    >
                                        GRAB OFFER
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default CouponDetails;
