import React, { useEffect, useState } from 'react'
import ProductDetails from './ProductDetails'
import ProductPartners from './ProductPartners'
import { Button, CardMedia, FormHelperText, IconButton, Modal, Paper, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import SimilarProducts from './SimilarProducts'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { cartProductCreateAction, cartProductDeleteAction, cartUpdateAction, colorVariantByProductId, imagesGetByProductId, partnerDetailByProductId, partnersFromProductSize, productCheckCartAction, productDetailByProductId, productReviewByProductId, productWishCheckAction, productWishCreateAction, productWishDeleteAction, retailPartnersFromProductSize, retailProductCheckCartAction, retailSizeGetByProductId, sizeGetByProductId } from '../../../store/slices/retail/ProductDetails/ProductDetailsActions'
import { useDispatch, useSelector } from 'react-redux'
import minus from "../../../assests/images/minus.png";
import plus from "../../../assests/images/plus.png";
import { GlobalContext } from '../../../Context'

import { useTheme } from '@emotion/react'
import useScreenSize from '../../../utils/ScreenWidthHight'
import { cartRefresh, refreshIdUpdate } from '../../../store/slices/CommonSlice'
const ProductModal = ({ modalOpen, handleModalClose, productDetails, handleModalProductId, modalProductId, removeItemWishList = (() => { }) }) => {
  const auth = useSelector(state => state.auth)
  const theme = useTheme()
  const pathname = useLocation().pathname
  const [SMSizes, setSMSizes] = useState([])
  const [SMColors, setSMColors] = useState([])
  const [SMPartnerDetails, setSMPartnerDetails] = useState([])
  const [selectedChart, setSelectedChart] = useState(0);
  const [quantities, setQuantities] = useState([])
  const [partnerLoading, setPartnerLoading] = useState(true)
  const [isRetail, setIsRetail] = useState(pathname.includes("retail"))


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  useEffect(() => {
    if (modalProductId !== null) {
      sizesGetBySMProductIdCall({ Product_Id: modalProductId })
      colorVariantBySMProductIdCall({ Product_Id: modalProductId })
    }
  }, [modalProductId])

  useEffect(() => {
    setSelectedChart(SMSizes[0]?.Product_Size_Id)
  }, [SMSizes])

  const sizesGetBySMProductIdCall = async (formData) => {
    const response = await retailSizeGetByProductId(formData)
    if (response.status) {
      setSMSizes(response.data.map((size, index) => ({ productSize: size.Product_Size, productAvailableStock: size.Availability_Stock, index: index, Size_Measurement: size.Size_Measurement, Size_Type: size.Size_Type, Product_Size_Id: size.Product_Size_Id })))
    }
    else {
      setSMSizes([])
      setPartnerLoading(false)
    }
  }

  const colorVariantBySMProductIdCall = async (formData) => {
    const response = await colorVariantByProductId(formData)
    if (response.status) {
      setSMColors(response.data.map((colorvariantImage, index) => ({ title: colorvariantImage.Color, image: colorvariantImage.Product_Image, productId: colorvariantImage.Product_Id, index: index, default: colorvariantImage.Product_Id === formData.Product_Id })))
    }
  }


  const partnersofSMFromProductSizeCall = async (formData) => {
    setPartnerLoading(true)
    const response = await retailPartnersFromProductSize(formData)
    if (response.status) {
      setSMPartnerDetails(response.data.map((partner, index) => ({ image: partner.Logo_Path, partnerName: partner.Parnter_Name, partnerId: partner.Partner_Details_Id, partnerSellingPrice: partner.Partner_Selling_Price, Partner_Product_Id: partner.Partner_Product_Id, Product_Id: partner.Product_Id, Partner_Details_Id: partner.Partner_Details_Id, Availability_Stock: partner.Availability_Stock })))
      setPartnerLoading(false)
    }
    else {
      setSMPartnerDetails([])
      setPartnerLoading(false)
    }
  }

  useEffect(() => {
    SMPartnerDetails.map((partner, index) => {
      const formData = {
        User_Email_Id: auth?.user?.Email_Id,
        Product_ID: partner?.Product_Id,
        Partner_Detail_Id: partner?.partnerId,
        Size: selectedChart
      }
      productCheckCartActionCall(formData)

    })
  }, [SMPartnerDetails, selectedChart])

  useEffect(() => {
    if (modalProductId !== null && SMSizes.length > 0 && selectedChart !== 0 && selectedChart !== undefined) {
      const formData = {
        Product_Id: modalProductId,
        Prodcut_Size_Id: selectedChart
      }
      partnersofSMFromProductSizeCall(formData)
    }

  }, [modalProductId, SMSizes, selectedChart])

  const productCheckCartActionCall = async (formData) => {
    const response = await retailProductCheckCartAction(formData)
    if (response.status) {
      setQuantities(prevState => ([...prevState, response.data]))
    }

  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose} >
      <Box sx={{ ...style, width: { md: "50%", xs: "80%" }, maxHeight: "80vh", overflowY: "scroll", '&::-webkit-scrollbar': { display: 'none' }, borderRadius: 5, position: "relative" }}>
        <Box sx={{ width: "100%", height: "40%", display: "flex", flexWrap: "wrap", gap: 4 }} >
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "20%" }, display: "flex", justifyContent: "center", alignItems: "center" }} >
            {partnerLoading ?
              <Skeleton variant='rounded' sx={{ width: "100%", height: 138, }} /> : <img
                src={productDetails?.Product_Image+"?q=10"}
                style={{ width: "100%", height: 138, objectFit: "contain" }}
              />}
          </Box>
          <Box sx={{ width: { md: "50%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 3 }} >
            <Box sx={{ width: "100%" }} >
              {partnerLoading ?
                <Skeleton variant='text' sx={{ width: "70%" }} />
                : <Typography sx={{ color: theme.palette.text.primary }} >{productDetails?.Product_Name}</Typography>}
            </Box>
            <Box sx={{ width: "100%", }} >
              {partnerLoading ?
                <Skeleton variant='rectangular' sx={{ width: "70%", height: "30px" }} />
                : <ColorCharts SMColors={SMColors} handleModalProductId={handleModalProductId} />}
            </Box>
            {SMSizes.length > 0 && <> <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
              {partnerLoading ?
                <Skeleton variant='rectangular' sx={{ width: "70%", height: "30px" }} />
                : <Typography sx={{ color: theme.palette.text.primary }} >Select Variant</Typography>}
            </Box>
              <Box sx={{ width: "100%", }} >
                {partnerLoading ?
                  <Skeleton variant='rectangular' sx={{ width: "70%", height: "30px" }} />
                  :
                  <SizeCharts selectedChart={selectedChart} setSelectedChart={setSelectedChart} SMSizes={SMSizes} />
                }
              </Box></>
            }

          </Box>

        </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", }} >
          <Box>
            <Typography sx={{ color: theme.palette.text.primary }} >Preferred Partners</Typography>
          </Box>
          <Box sx={{ width: "100%", maxHeight: { xs: 200, sm: 370, md: 400 }, overflowY: "scroll", display: 'flex', flexWrap: "wrap", gap: 2, justifyContent: "center", '&::-webkit-scrollbar': { display: 'none' }, }} >
            {(SMPartnerDetails.length > 0 && SMSizes.length > 0) && !partnerLoading ?
              SMPartnerDetails?.map((partner, index) => {
                return (
                  <PreferedModalCard key={index} email={auth?.user?.Email_Id} selectedChart={selectedChart} image={partner.image} partnerName={partner.partnerName} partnerSellingPrice={partner.partnerSellingPrice} minus={minus} plus={plus} partnerId={partner.partnerId} Partner_Product_Id={partner.Partner_Product_Id} Product_Id={partner.Product_Id} Quantity={quantities[index]} Partner_Details_Id={partner?.Partner_Details_Id} Availability_Stock={partner.Availability_Stock} removeItemWishList={removeItemWishList} />
                )
              })
              :
              ((SMSizes.length == 0 && !partnerLoading) ? <FormHelperText error sx={{ fontSize: "20px" }} >No Stock Available</FormHelperText> : (!partnerLoading && <Typography>No Parnters Found</Typography>))
            }
            {partnerLoading &&
              Array(4).fill(1).map((element, index) => {
                return (
                  <Skeleton key={index} variant='rectangular' sx={{ width: { md: "45%", sm: "70%", xs: "100%" }, maxHeight: { xs: 120, sm: 130, md: 170 }, minHeight: { xs: 120, sm: 130, md: 170 }, }} />
                )
              })
            }
          </Box>
        </Box>
        <IconButton
          sx={{ position: "absolute", top: 1, right: 1 }}
          onClick={handleModalClose}
        >
          <CardMedia
            image={theme.palette.mode === "dark" ? require('../../../assests/images/closeIcon.png') : require('../../../assests/images/closeIcon.png')}
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
      </Box>

    </Modal>
  )
}

export default ProductModal

export const PreferedModalCard = ({ image, partnerName, selectedChart, partnerSellingPrice, minus, plus, partnerId, Partner_Product_Id, email, Product_Id, Partner_Details_Id, Availability_Stock, removeItemWishList }) => {
  const winSize = useScreenSize()
  const theme = useTheme()
  const [QuantityHandle, setQuantityHandle] = useState(0)
  const [Cart_Quantity, setCart_Quantity] = useState({ User_Cart_Id: '', Quantity: 0, Availability_Stock: 60 })
  const [cartIdHandle, setCartIdHandle] = useState()
  const [wishIdHandle, setWishIdHandle] = useState()
  const [wishState, setWishState] = useState(false)
  const auth = useSelector(state => state.auth)
  const [clicked, setClicked] = useState(true)
  const navigate = useNavigate()
  const { toolTipActive } = React.useContext(GlobalContext);
  const address = useSelector(state => state.commonReducer.Address)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (auth.isAuthenticated) {
      wishCheckActionCall({

        "User_Email_Id": email,
        "Product_Id": Product_Id,
        "Partner_Details_Id": Partner_Details_Id

      })
    }

  }, [wishState, QuantityHandle])

  useEffect(() => {

    if (auth.isAuthenticated)
      productCheckCartActionCall({
        User_Email_Id: email,
        Partner_Product_ID: Partner_Product_Id,
        Size: selectedChart
      })
    setClicked(true)
    setQuantityHandle(0)
  }, [selectedChart, Partner_Product_Id])

  const wishCheckActionCall = async (formData) => {
    const response = await productWishCheckAction(formData)
    if (response.status) {
      if (response.data) {
        setWishState(response.data?.length > 0 ? true : false)
        setWishIdHandle(response.data[0].Wish_Id)
      }
    }
    else
      setWishState(false)
  }

  const productCheckCartActionCall = async (formData) => {
    setLoading(true)
    const response = await retailProductCheckCartAction(formData)
    if (response.status) {
      setCart_Quantity(response.data)
      setQuantityHandle(response.data.Quantity)
      setCartIdHandle(response.data.User_Cart_Id)
    }
    else {
      setQuantityHandle(0)
      setCartIdHandle("")
      setCart_Quantity(state => ({ ...state, Availability_Stock: Number(Availability_Stock) || 0 }))
    }
    setLoading(false)
  }

  return (
    <Box sx={{ position: "relative", width: { md: "45%", sm: "70%", xs: "100%" }, maxHeight: { xs: 120, sm: 130, md: 170 }, minHeight: { xs: 120, sm: 130, md: 170 }, border: `1px solid ${theme.palette.input.borderColor}`, display: 'flex', flexDirection: { md: "row", sm: "row", xs: "row" }, alignItems: { md: "center", sm: "center", xs: "center" }, padding: "10px 5px", borderRadius: 2, gap: 1 }} >
      <Box sx={{ width: "30%" }}>
        <img
          src={image+"?q=10"}
          style={{ width: "100%", height: 50, objectFit: "contain" }}
        />
      </Box>
      <Box sx={{ width: { md: "60%", xs: "100%" }, display: "flex", flexDirection: "column", gap: { md: 2, sm: 1, xs: 1 } }} >
        <Box sx={{ width: "100%", display: "flex", flexDirection: { md: "row", }, justifyContent: "flex-start", alignItems: "center" }} >
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} >
            {winSize.screenSize.width < 600 ?
              <Typography sx={{ color: theme.palette.text.primary, whiteSpace: 'nowrap', }} >{partnerName.length > 20 ? partnerName.slice(0, 25) + "..." : partnerName}</Typography>
              : <Typography sx={{ color: theme.palette.text.primary }} >{partnerName.length > 30 ? partnerName.slice(0, 30) + "..." : partnerName}</Typography>
            }</Box>
        </Box>
        <Typography  > &#8377; {partnerSellingPrice}</Typography>
        <Box sx={{ width: { xs: "90%", md: "100%", sm: "100%" }, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }} >
          {QuantityHandle !== 0 && Boolean(Cart_Quantity?.User_Cart_Id) ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={async () => {
                  const formData = {
                    User_Cart_Id: cartIdHandle,
                    Quantity: QuantityHandle - 1,
                  }
                  if (QuantityHandle == 1) {
                    const response = await cartProductDeleteAction({
                      User_Cart_Id: cartIdHandle
                    })
                    if (response.status) {
                      productCheckCartActionCall({
                        User_Email_Id: email,
                        Partner_Product_ID: Partner_Product_Id,
                        Size: selectedChart
                      })
                    }

                  }
                  const response = await cartUpdateAction(formData)
                  if (response.status) {
                    productCheckCartActionCall({
                      User_Email_Id: email,
                      Partner_Product_ID: Partner_Product_Id,
                      Size: selectedChart
                    })
                    toolTipActive("success", "Quantity Decreased");
                  }
                  else {
                    toolTipActive("Failed", "Unable to Connect with Server, check your network and try again");
                  }
                }}
                disabled={(QuantityHandle === 1 || Number(Cart_Quantity?.Availability_Stock) === 0) ? true : false}
              >
                <img
                  src={minus}
                  alt="Minus Icon"
                  style={{
                    width: "20px",
                    height: "auto",
                    marginRight: "17px",
                    cursor: "pointer",
                    filter: (QuantityHandle === 1 || Number(Cart_Quantity?.Availability_Stock) === 0) ? "blur(2px)" : "blur(0px)"

                  }}

                />
              </IconButton>
              <Typography
                variant="body1"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  padding: "2px 6px",
                  borderRadius: "5px",
                  border: "1px solid",
                  borderColor: theme.palette.extra.cartBorderColor,

                }}
              >
                {QuantityHandle}
              </Typography>
              <IconButton
                onClick={async () => {
                  const formData = {
                    User_Cart_Id: cartIdHandle,
                    Quantity: QuantityHandle + 1,
                  }
                  const response = await cartUpdateAction(formData)
                  if (response.status) {
                    productCheckCartActionCall({
                      User_Email_Id: email,
                      Partner_Product_ID: Partner_Product_Id,
                      Size: selectedChart
                    })
                    toolTipActive("success", "Quantity Increased");
                  }
                  else {
                    toolTipActive("Failed", "Unable to Connect with Server, check your network and try again");
                  }
                }}
                disabled={(QuantityHandle === Number(Cart_Quantity?.Availability_Stock) || Number(Cart_Quantity?.Availability_Stock) === 0) ? true : false}              >
                <img
                  src={plus}
                  alt="Plus Icon"
                  style={{
                    width: "20px",
                    height: "auto",
                    marginLeft: "17px",
                    cursor: "pointer",
                    filter: (QuantityHandle === Number(Cart_Quantity?.Availability_Stock) || Number(Cart_Quantity?.Availability_Stock) === 0) ? "blur(2px)" : "blur(0px)"
                  }}

                />
              </IconButton>
            </Box>
          ) : (
            <Button
              style={{
                backgroundColor: theme.palette.extra.primary,
                color: theme.palette.primary.contrastText,
                width: "70%",
                overflow: "hidden",
                height: "40px",
              }}
              onClick={
                auth?.isAuthenticated ?
                  async () => {
                    const formData = {
                      Partner_Details_Id: partnerId,
                      Partner_Product_Id: Partner_Product_Id,
                      Quantity: 1,
                      Size: selectedChart,
                      User_Email_Id: email,

                    }
                    if (address?.User_Address_Id) {
                      formData.User_Address_Id = address?.User_Address_Id
                    }
                    if (Number(Cart_Quantity?.Availability_Stock) !== 0 && clicked) {
                      setClicked(false)
                      const response = await cartProductCreateAction(formData)
                      if (response.status) {
                        dispatch(refreshIdUpdate(Product_Id))
                        productCheckCartActionCall({
                          User_Email_Id: email,
                          Partner_Product_ID: Partner_Product_Id,
                          "Partner_Details_Id": Partner_Details_Id,
                          Size: selectedChart
                        })
                        setQuantityHandle(1)
                        dispatch(cartRefresh())
                        toolTipActive("success", "Added to Cart")
                        if (wishState) {
                          const response = await productWishDeleteAction({ Wish_Id: wishIdHandle })
                          if (response.status) {
                            removeItemWishList(wishIdHandle)
                            setWishState(false)
                          }
                        }
                      }
                      else {
                        toolTipActive("error", "can't Add to Cart")
                      }
                    }

                  } :
                  () => {
                    navigate('/auth/signin')
                  }
              }
              disabled={Number(Cart_Quantity?.Availability_Stock) === 0}
            >
              Add to Cart
            </Button>
          )}

        </Box>
        {Number(Cart_Quantity?.Availability_Stock) <= 20 ? <Box sx={{ width: "90%" }} >
          {Number(Cart_Quantity?.Availability_Stock) === 0 ?
            <Typography sx={{ color: theme.palette.error.main, fontSize: "14px" }} >No Stock Availble</Typography>
            : <Typography sx={{ color: theme.palette.error.main, fontSize: "14px" }} >!!! Hurry Up {Number(Cart_Quantity?.Availability_Stock)} Left</Typography>}
        </Box> : QuantityHandle === Number(Cart_Quantity?.Availability_Stock) &&
        <Typography sx={{ color: theme.palette.error.main, fontSize: "14px" }} >Max Quantity Limit Reached</Typography>
        }
      </Box>

      <IconButton sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={
          auth.isAuthenticated ?
            async () => {
              const formData = {
                User_Email_Id: email,
                Product_Id: Product_Id,
                Partner_Details_Id: partnerId
              }
              if (wishState && auth.isAuthenticated) {
                const response = await productWishDeleteAction({ Wish_Id: wishIdHandle })
                if (response.status) {
                  dispatch(refreshIdUpdate(Product_Id))
                  setWishState(false)
                  setWishIdHandle("")
                  toolTipActive("success", "Product is Removed from Wishlist");
                }
              }
              else {
                const response = await productWishCreateAction(formData)
                if (response.status) {
                  dispatch(refreshIdUpdate(Product_Id))
                  setWishState(response.status)
                  toolTipActive("success", "Product is Added to Wishlist");

                }
              }
            }
            :
            () => {
              navigate('/auth/signin')
            }
        }
      >
        <CardMedia
          image={require(wishState ? `../../../assests/images/wishlistActive.png` : `../../../assests/images/wishlistInActive.png`)}
          sx={{ width: 20, height: 20 }}
        />
      </IconButton>
    </Box>
  )
}


const SizeCharts = ({ setSelectedChart, SMSizes, selectedChart }) => {
  const pathname = useLocation().pathname
  const handleChartClick = (title) => {
    setSelectedChart(title.Product_Size_Id);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row" },
        gap: 1,
        alignItems: { xs: "flex-start", md: "flex-start" },
        overflowY: { xs: "scroll", md: "hidden" },
        '&::-webkit-scrollbar': {
          display: 'none'
        },

      }}
    >
      {SMSizes?.map((size, index) => (
        <SizesButton
          key={index}
          isActive={selectedChart === size.Product_Size_Id}
          title={size.productSize}
          ext={(size?.Size_Measurement)}
          onClick={() => {
            handleChartClick(size)
          }}
          productAvailableStock={size.productAvailableStock}
        />
      ))}
    </Box>
  );
};

const SizesButton = ({ title, onClick, isActive, productAvailableStock, ext = "" }) => {
  const theme = useTheme()
  return (
    <Box>
      <Button
        onClick={onClick}
        sx={{
          minWidth: 48,
          // maxWidth: 48,
          minHeight: 48,
          borderRadius: 3,
          alignSelf: "flex-start",
          border: `1px solid ${isActive ? theme.palette.input.borderColor : "none"}`,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {title.toUpperCase()}
        <Typography sx={{ fontSize: ".6rem" }} >{ext?.substring(ext?.indexOf("(")+1,ext?.indexOf(")")) || ext}</Typography>
      </Button>
    </Box>
  )
}

const ColorImageCard = ({ title, onClick, isActive, image }) => {
  const theme = useTheme()
  return (
    <IconButton onClick={onClick} >
      <img
        src={image+"?q=10"}
        style={{
          minWidth: 48,
          maxWidth: 58,
          maxHeight: 58,
          borderRadius: 3,
          alignSelf: "flex-start",
          border: `2px solid ${isActive ? theme.palette.input.borderColor : "none"}`,
          backgroundColor: title
        }}

      />
    </IconButton>
  )
}

const ColorCharts = ({ SMColors, handleModalProductId }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row" },
        gap: 1,
        alignItems: { xs: "flex-start", md: "flex-start" },
        overflowY: { xs: "scroll", md: "hidden" },
        '&::-webkit-scrollbar': {
          display: 'none'
        },
      }}
    >
      {SMColors.map((color, index) => (
        <ColorImageCard
          key={color.productId + color.default}
          isActive={color.default}
          title={color.title}
          image={color.image}
          onClick={() => {
            handleModalProductId(color.productId)
          }}
        />
      ))}
    </Box>
  );
};

