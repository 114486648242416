import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerLovedFashion } from "../../../store/slices/retail/home/customerLovedSlice";
import { Card, Typography, Grid, ButtonBase, Box, Button } from "@mui/material";
import useScreenSize from "../../../utils/ScreenWidthHight";
import { useNavigate } from "react-router-dom";

const CustomerMostLoved = ({ data, itemData }) => {
  const winSize = useScreenSize();
  const navigate = useNavigate()
  // const cardWidth = useMemo(() => {
  //   let cardWidth = winSize.getWidthForGiveInput(2) - 20;
  //   if (cardWidth <= 640) {
  //     cardWidth = 640;
  //   }
  //   if (winSize.screenSize.width <= cardWidth) {
  //     cardWidth = winSize.screenSize.width - 20;
  //   }
  //   return cardWidth + "px";
  // }, [winSize.screenSize.width]);

  const dispatch = useDispatch();
  const { customerLovedFashion: items, } = useSelector((state) => state.customerLoved);

  useEffect(() => {
    dispatch(fetchCustomerLovedFashion());
  }, []);

  const handleItemClick = (item) => {
    // Handle the item click logic here
  };

  return (
    <>
      {(
        <Card
          sx={{
            height: items.length <= 2 ? "250px" : "462px",
            width: { sm: "550px", xs: '100%' },
            // maxWidth: cardWidth,
            // boxShadow: "0px 8px 38px 7px rgba(0, 0, 0, 0.25)",
            borderRadius: "0px",
          }}
        >
          <Box
            sx={{
              height: "50px",
              marginLeft: "19px",
              marginBottom: "10px"
            }}
          >
            <Typography sx={{ fontSize: '19px', fontWeight: 'bold', }}>
              {itemData.title}
            </Typography>
          </Box>
          {winSize.screenSize.width > 600 ? <Grid container rowGap={2} marginLeft={2.5} sx={{ display: "flex", justifyContent: "space-around", }} >
            {data.slice(0, 4).map((item, index) => (
              <Grid item xs={index % 2 === 0 ? 5 : 5} key={index}>
                {(
                  <Button
                    sx={{
                      padding: 0,
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                    onClick={() => { navigate(itemData.onClickLink + item[itemData.idField]) }}
                    disableRipple
                  >
                    <img
                      src={item[itemData.imageField]+"?q=10"}
                      alt={`Item ${index + 1}`}
                      style={{
                        width: index % 2 === 0 ? "95%" : "170px",
                        height: "185px",
                        objectFit: "contain",
                      }}
                    />
                  </Button>
                )}
              </Grid>
            ))}
          </Grid> :
            <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}} > 
              <Box sx={{ width: "90%", display: "flex", flexWrap: "wrap", justifyContent: "space-around",rowGap:"15px" }} >
                {data.slice(0, 4).map((item, index) => (
                  <Box sx={{ width: "48%" }} key={index}>
                    {(
                      <Button
                        sx={{
                          padding: 0,
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                        onClick={() => { navigate(itemData.onClickLink + item[itemData.idField]) }}
                        disableRipple
                      >
                        <img
                          src={item[itemData.imageField]+"?q=10"}
                          alt={`Item ${index + 1}`}
                          style={{
                            width: index % 2 === 0 ? "150px" : "170px",
                            height: "185px",
                            objectFit: "contain",
                          }}
                        />
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          }
        </Card>
      )}
    </>
  );
};

export default CustomerMostLoved;