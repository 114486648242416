import React, { useContext, useEffect, useState } from "react";
import { Typography, Box, Button, IconButton, Skeleton } from "@mui/material";
import deleteIcon from '../../../assests/images/delete.png';
import shareIcon from '../../../assests/images/shareIcons.png';
import { useTheme } from "@emotion/react";
import { cartProductCreateAction, productCheckCartAction, productWishDeleteAction } from "../../../store/slices/retail/ProductDetails/ProductDetailsActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../Context";
import { envConfig } from "../../../store/slices/CommonAxios";
import { RWebShare } from "react-web-share";
import AutoAdjustText from "../../../utils/AutoAdjustText";
import useScreenSize from "../../../utils/ScreenWidthHight";

const WishlistCard = ({ data = {}, name = "", productname = "", logoPath = "", originalPrice = "", sellingPrice = "", onDelete = (() => { }), formData = {}, productSearchCall = (() => { }), handleSetModalProductId = (() => { }), handleModalOpen = (() => { }), isSkeleton = false, cardWidth = "", isActive = true, }) => {
    const BASEURL = envConfig.BASEURL;
    const theme = useTheme();
    const [cart, setCart] = useState(false);
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    const { toolTipActive } = useContext(GlobalContext);
    const winSize = useScreenSize();

    useEffect(() => {
        if (!isSkeleton)
            cartCheck({
                User_Email_Id: auth.user.Email_Id,
                Partner_Product_ID: data.Partner_Product_Id,
                Size: data.Size
            });
    }, [data, auth]);

    const cartProductCreateActionCall = async (formData) => {
        const response = await cartProductCreateAction(formData);
        if (response.status) {
            toolTipActive("success", "Product Added to Cart");
            setCart(true);
            navigate('/accounts/cart');
        }
    };

    const cartCheck = async (formData) => {
        const response = await productCheckCartAction(formData);
        if (response.status) {
            if (parseInt(response.data?.Quantity) > 0) {
                setCart(true);
            }
        }
    };

    if (!isSkeleton)
        return (
            <Box sx={{ width: { md: cardWidth, sm: cardWidth, xs: "40%" }, paddingX: { xs: 2, sm: 1 } }} >
                <Box
                    sx={{
                        borderRadius: "24px",
                        marginBottom: "10px",
                        height: { md: "360px", xl: "390px", xs: "270px" },
                        border: "1px solid",
                        borderColor: theme.palette.primary.main,
                        position: "relative",
                        width: { md: cardWidth, sm: cardWidth, xs: "100%" },
                        maxHeight: { md: "360px", xl: "390px", xs: "270px" },
                    }}
                >
                    <RWebShare
                        data={{
                            url: `${BASEURL}/retail/products/${data.Product_Id}`,
                            title: name,
                        }}
                    >
                        <img
                            src={shareIcon}
                            alt="Share Icon"
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                width: "20px",
                                height: "auto",
                                cursor: isActive ? "pointer" : "default",
                                opacity: isActive ? 1 : 0.5,
                                pointerEvents: isActive ? "auto" : "none",
                                transition: "opacity 0.3s ease-in-out",
                            }}
                        />
                    </RWebShare>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            padding: '10px',
                            paddingY: "30px"
                        }}
                    >

                        <img
                            src={logoPath+"?q=10"}
                            alt="Product Image"
                            style={{
                                height: winSize.screenSize.width < 500 ? "110px" : '50%',
                                maxHeight: winSize.screenSize.width < 500 ? "120px" : '50%',
                                objectFit: "contain",
                                cursor: isActive ? "pointer" : "default",
                                opacity: isActive ? 1 : 0.5,
                                pointerEvents: isActive ? "auto" : "none",
                                transition: "opacity 0.3s ease-in-out",
                            }}
                            onClick={() => {
                                if (window.location.pathname.includes("grocery"))
                                    navigate('/grocery/products/' + data.Product_Id);
                                else
                                    navigate('/retail/products/' + data.Product_Id);
                            }}
                        />

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                rowGap: "10px",
                                height: {md: "50%", sm:'50%',xs:'48%' },
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: "column" }}>
                                    <AutoAdjustText style={{ width: '100%', fontSize: '14px', height: 'auto', color: theme.palette.text.secondary }} numLines={1} color="textSecondary">
                                        {name}
                                    </AutoAdjustText>
                                    <AutoAdjustText style={{ width: '100%', fontSize: '14px', height: 'auto' }} numLines={1}>
                                        {productname}
                                    </AutoAdjustText>
                                </Box>
                                <img
                                    src={deleteIcon}
                                    alt="Delete Icon"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer"
                                    }}
                                    onClick={async () => {
                                        const response = await productWishDeleteAction({ Wish_Id: data.Wish_Id });
                                        if (response.status) {
                                            toolTipActive("success", "Removed From Wishlist");
                                            productSearchCall(formData);
                                        }
                                    }}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" flexDirection={"row"} justifyContent={"space-between"} >
                                <Typography component="span" color="textSecondary"
                                    sx={{
                                        fontSize: { md: (winSize.screenSize.width < 1100 ? "12px" : "14px"), sm: "10px", xl: "16px", xs: "12px" },
                                        fontWeight: 'bold '
                                    }}
                                >
                                    &#8377; {sellingPrice}
                                </Typography>

                                <Typography
                                    component="span"
                                    color="textSecondary"
                                    sx={{
                                        textDecoration: "line-through",
                                        fontSize: { md: (winSize.screenSize.width < 1100 ? "10px" : "14px"), xl: "16px", xs: "6px", sm: "10px" },
                                        marginLeft: '60px'
                                    }}
                                >
                                    &#8377; {originalPrice}
                                </Typography>
                            </Box>
                            {
                                <Box sx={{
                                    width: { md: "100%", xs: "100%", sm: "90%" }, display: "flex", justifyContent: "center", alignItems: "center",
                                    bottom: { xs: 13 },
                                }} >
                                    <Button variant="outlined" sx={{
                                        borderRadius: 24, '&:hover': { backgroundColor: theme.palette.primary.main, color: 'Window', },
                                        marginY: { md: "3px", xl: "4px" },
                                        height: { xs: "30px", md: "auto" },
                                        fontSize: { md: ("14px"), sm: "12px" },
                                        width: { md: "100%", sm: "80%", xs: "100%" },
                                        cursor: isActive ? "pointer" : "default",
                                        opacity: isActive ? 1 : 0.5,
                                        pointerEvents: isActive ? "auto" : "none",
                                        transition: "opacity 0.3s ease-in-out",

                                    }} onClick={() => {
                                        handleModalOpen(true);
                                        handleSetModalProductId(data.Product_Id);
                                      
                                        }}  >Add To Cart</Button>
                                </Box>
                            }
                            <Box sx={{ minHeight: '3px', width: '100%' }}>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    else
        return (
            <Box sx={{ width: cardWidth, p: 1 }} >
                <Box
                    sx={{
                        borderRadius: "24px",
                        marginBottom: "10px",
                        height: "390px",
                        border: "1px solid",
                        borderColor: theme.palette.primary.main,
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            padding: '30px'
                        }}
                    >
                        <Skeleton
                            variant="rounded"
                            style={{
                                height: '50%',
                                objectFit: "fill",
                            }}
                        />

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "50%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Skeleton variant="text" />
                                <Skeleton variant="circular" width={20} height={20} />
                            </Box>
                            <Box display="flex" alignItems="center" flexDirection={"row"} justifyContent={"space-between"} >
                                <Skeleton variant="text" sx={{ width: "50px" }} />
                                <Skeleton variant="text" sx={{ width: "50px" }} />
                            </Box>
                            <Skeleton
                                variant="rounded"
                                height={35}
                                width="100%"
                                sx={{ my: "5px", borderRadius: "20px" }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
};

export default WishlistCard;
