import React, { useMemo } from "react";
import { Card, CardActionArea, CardMedia, Typography, Box, Grid, Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import useScreenSize from "../../../utils/ScreenWidthHight";

const CouponsPageCard = ({ data = {}, onClick = (() => { }), discount = "", name = "", validateWithDate = "", esiCashBack = "", imagePath = "", isSkeleton = false, discountType, Discount_Label }) => {
    const theme = useTheme();
    const winSize = useScreenSize();

    const cardWidth = useMemo(() => {
        let cardWidth = (winSize.getWidthForGiveInput(3));
        if (cardWidth <= 346) {
            cardWidth = 346
        }
        if (winSize.screenSize.width <= cardWidth) {
            cardWidth = winSize.screenSize.width
        }
        return cardWidth + "px"
    }, [
        winSize.screenSize.width
    ])
    const discountLabel = discountType === 'A' ? '/-' : '%';

    if (!isSkeleton)
        return (
            <Card sx={{ border: "2px solid" + theme.palette.primary.main, borderRadius: "12px", width: {xs:"94%",sm:'346px'}, }}>
                <CardActionArea onClick={() => onClick(data)} sx={{ width: cardWidth,maxHeight: "182px", minHeight: "182px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {(discount || esiCashBack) && (
                        <Box sx={{ backgroundColor: theme.palette.primary.main, color: "#fff", padding: "5px", height: "182px", width: '27px', display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography sx={{
                                fontSize: '22px',
                                writingMode: 'vertical-rl',
                                transform: 'rotate(180deg)',
                                textOrientation: 'mixed',
                                fontWeight: 'bold'
                            }}>
                                {Discount_Label} {discount ? `${discount}${discountLabel} Off` : `${esiCashBack}${data.ESI_Cashback_Type === "A" ? "/-" : data.ESI_Cashback_Type === "R" ? "Rewards" : '% Off'}`}
                            </Typography>
                        </Box>
                    )}
                    <Box sx={{ width: {md:"25%",sm:'25%', xs:'35%'}, ml: '10px' }}>
                        <CardMedia
                            component="img"
                            image={imagePath+"?q=10"}
                            alt="Coupon Image"
                            style={{ height: "182px", width: "100%", objectFit: "contain" }}
                        />
                    </Box>
                    <Box sx={{ width: {md:"75%", sm:'75%', xs:'65%'}, ml: '10px' }}>
                        <Typography sx={{ whiteSpace: "nowrap", fontSize: "22px", marginTop: '-10px', fontWeight: "bold" }}>{name?.slice(0, 6)}</Typography>
                        <Typography sx={{ color: theme.palette.text.secondary, fontSize: '11px', whiteSpace: "nowrap" }}>Coupon will Expire on</Typography>
                        {esiCashBack && (
                            <Typography sx={{
                                color: theme.palette.text.secondary, whiteSpace: "nowrap", fontSize: '11px', fontWeight: 'bold'
                            }}>
                                CASHBACK  {data.ESI_Cashback_Type === "A" && "₹"} {esiCashBack} {data.ESI_Cashback_Type === "A" ? "/-" : data.ESI_Cashback_Type === "R" ? "Rewards" : '% Off'}
                            </Typography>
                        )}
                        <Typography sx={{ color: theme.palette.text.secondary, whiteSpace: "nowrap", fontSize: '11px', }}>{validateWithDate?.slice(0, 10)}</Typography>
                    </Box>
                </CardActionArea>
            </Card >

        );
    else {
        return (
            <Card style={{ border: "0px solid" + theme.palette.primary.main, borderRadius: "12px", maxWidth: '326px' }}>
                <Skeleton variant="rectangular" sx={{ minWidth: '326px', minHeight: "182px", }} />
            </Card >

        );
    }
};

export default CouponsPageCard;