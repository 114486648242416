import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  CardActionArea,
  IconButton,
  CardMedia,
  Skeleton,
} from "@mui/material";

import cartIcon from "../../../assests/images/shareIcons.png";
import minus from "../../../assests/images/minus.png";
import plus from "../../../assests/images/plus.png";
import useScreenSize from "../../../utils/ScreenWidthHight";
import heartunfill from '../../../assests/images/wishlistInActive.png'
import heartfill from '../../../assests/images/wishlistActive.png'
import { useLocation, useNavigate } from "react-router-dom";
import { esES } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { productWishCheckAction, productWishCreateAction, productWishDeleteAction } from "../../../store/slices/retail/ProductDetails/ProductDetailsActions";
import { GlobalContext } from "../../../Context";
import { envConfig } from "../../../store/slices/CommonAxios";
import { RWebShare } from "react-web-share";
import { fontWeight, margin } from "@mui/system";
import AutoAdjustText from "../../../utils/AutoAdjustText";
import { refreshIdUpdate } from "../../../store/slices/CommonSlice";
const AllProductsCard = ({
  image = "",
  brandName = "",
  shortDescription = "",
  originalPrice = "",
  partnerSellingPrice = "",
  handleNavigation = (() => { }),
  productName = "",
  onClick,
  item = {},
  isSkeleton = false
}) => {
  const theme = useTheme();
  const winSize = useScreenSize();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const allCardRef = useRef()
  const cardWidth = useMemo(() => {
    let cardWidth = winSize.getWidthForGiveInput(8);
    if (cardWidth <= 170) {
      cardWidth = 170;
    }
    if (winSize.screenSize.width / 2 < cardWidth + 50) {
      cardWidth = winSize.screenSize.width / 2 - 20;
    }
    return cardWidth + "px";
  }, [winSize.screenSize.width]);
  const BASEURL = envConfig.BASEURL;
  const [cart, setCart] = useState(0);
  const [showCartAdjustment, setShowCartAdjustment] = useState(false);
  const [wishState, setWishState] = useState(false)
  const [wishIdHandle, setWishIdHandle] = useState("")
  const path = useLocation().pathname
  const { toolTipActive } = React.useContext(GlobalContext);
  const Refresh_Id = useSelector(state => state.commonReducer.Refresh_Id)

  useEffect(() => {
    if (auth?.isAuthenticated) {
      wishCheckActionCall({
        User_Email_Id: auth?.user?.Email_Id,
        Product_Id: item.Product_Id,
        Partner_Details_Id: item.Partner_Details_Id
      })
    }
  }, [auth?.isAuthenticated, wishState, item])

  useEffect(() => {
    if (Refresh_Id?.id === item?.Product_Id) {
      if (auth?.isAuthenticated) {
        setWishState(false)
        wishCheckActionCall({
          User_Email_Id: auth?.user?.Email_Id,
          Product_Id: item.Product_Id,
          Partner_Details_Id: item.Partner_Details_Id
        })
      }
    }
  }, [Refresh_Id, auth?.isAuthenticated])


  const wishCheckActionCall = async (formData) => {
    const response = await productWishCheckAction(formData)
    if (response.status) {
      if (response.data) {
        setWishState(response.data?.length > 0 ? true : false)

        if (response.data?.length > 0)
          setWishIdHandle(response.data[0].Wish_Id)

      }
    }
    else {
      setWishState(false)
    }
  }

  const handleDecrementSize = () => {
    if (cart > 1) {
      setCart((prevSize) => prevSize - 1);
    } else {
      setShowCartAdjustment(false);
    }
  };

  const handleIncrementSize = () => {
    setCart((prevSize) => prevSize + 1);
  };

  const handleAddToCart = () => {
    setShowCartAdjustment(true);
  };



  if (!isSkeleton)
    return (
      <Box
        sx={{
          width: { xs: (winSize.screenSize.width > 500 ? "28%" : "44%"), sm: (winSize.screenSize.width < 900 ? (winSize.screenSize.width < 700 ? "45%" : "28%") : "20%"), md: (winSize.screenSize.width < 1200 ? (winSize.screenSize.width < 1000 ? "28%" : "22%") : ("17%")) },
          maxWidth: { xs: "169px", sm: "auto" },
          minWidth: { md: path.includes('home') ? "unset" : "180px", xs: "unset", sm: "unset" },
          paddingX: { md: ((path.includes('/partner') || path.includes('/brand'))?"0":"1%"), sm: "1.7%", xs: "1.7%" },
          height: "434px",
          borderRadius: "12px",
          overflow: "hidden",
        }}
        ref={allCardRef}
        onMouseOver={() => {
          const cardAnimation = allCardRef.current.style
          if (path.includes('/home')) {
            cardAnimation.transform = "scale(110%)"
            cardAnimation.transitionDuration = ".5s"
          }
          else {
            cardAnimation.transform = "scale(105%) translateY(10px)"
            cardAnimation.transitionDuration = ".5s"
          }
        }}
        onMouseLeave={() => {
          const cardAnimation = allCardRef.current.style
          if (path.includes('/home')) {
            cardAnimation.transform = "translateY(0px)"
            cardAnimation.transitionDuration = ".2s"
          }
          else {
            cardAnimation.transform = "scale(0%)"
            cardAnimation.transform = "translateY(0px)"
            cardAnimation.transitionDuration = ".5s"
          }
        }}
      >

        <Box
          sx={{
            border: `0.5px solid`,
            borderColor: theme.palette.input.borderColor,
            borderRadius: "inherit",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >

          <IconButton

            sx={{
              position: "absolute",
              top: '0px',
              right: '0px',
              zIndex: 1,
            }}
            onClick={
              auth?.isAuthenticated ?
                async () => {
                  if (wishState) {
                    const response = await productWishDeleteAction({ Wish_Id: wishIdHandle })
                    if (response.status) {
                      setWishState(false)
                      setWishIdHandle("")
                      toolTipActive("success", "Product Removed From Wishlist...");
                      dispatch(refreshIdUpdate(item.Product_Id))
                    }
                    else {
                      dispatch(refreshIdUpdate(item.Product_Id))
                    }
                  }
                  else {
                    const response = await productWishCreateAction({
                      User_Email_Id: auth?.user?.Email_Id,
                      Product_Id: item.Product_Id,
                      Partner_Details_Id: item.Partner_Details_Id
                    })
                    if (response.status) {
                      setWishState(response.status)
                      toolTipActive("success", "Product Added To Wishlist...");
                      dispatch(refreshIdUpdate(item.Product_Id))
                    }
                  }
                }
                :
                () => {
                  navigate('/auth/signin')
                }
            }
          >
            <CardMedia
              image={wishState ? heartfill : heartunfill}
              alt="Heart Icon"
              sx={{
                width: 19,
                height: 19,
              }}
            />
          </IconButton>
          <CardActionArea onClick={() => {
            if (path.includes("retail"))
              navigate('/retail/products/' + item.Product_Id)
            else
              navigate('/grocery/products/' + item.Product_Id)
          }}
          >

            <img
              src={image+"?q=10"}
              alt={brandName}
              style={{ width: "100%", height: "202px", objectFit: "contain" }}
            />
          </CardActionArea>

          <Typography
            sx={{
              backgroundColor: theme.palette.extra.todaypricebgcolor,
              borderTopRightRadius: "25px",
              color: 'Window',
              paddingLeft: "8%",
              fontSize: { md: ".8rem", xs: (winSize.screenSize.width > 500 ? ".8rem" : ".9rem"), sm: ".7rem" },
            }}
          >
            {item.Discount_Type === "P" ? item.Discount_Label : item.Cashback_Label} {item.Discount_Type === "A" && "₹"} {item.Discount} {item.Discount_Type === "P" ? "%" : "/-"} OFF
          </Typography>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <Typography variant="body1" gutterBottom sx={{ fontWeight: "bold" }} >
                {brandName}
              </Typography> */}
              <AutoAdjustText style={{ height: "auto", width: "100%", fontWeight: '500', }} numLines={1}>
                {brandName}
              </AutoAdjustText>

              <RWebShare
                data={{
                  text: shortDescription,
                  url: `${BASEURL}/retail/products/${item.Product_Id}`,
                  title: brandName,
                }}
              >
                <IconButton>
                  <img
                    src={cartIcon}
                    alt="Cart Icon"
                    style={{ width: "20px", height: "auto", cursor: "pointer" }}
                  />
                </IconButton>
              </RWebShare>
            </Box>
            <AutoAdjustText style={{ height: "auto", width: "100%", fontWeight: 'bold', }} numLines={1}>
              {productName}
            </AutoAdjustText>
          </Box>

          <AutoAdjustText style={{ height: "40px", width: "100%", fontSize: "14px", color: theme.palette.text.secondary, }} numLines={2} >
            {shortDescription}
          </AutoAdjustText>
          <Box sx={{ width: "100%", display: "flex", flexDirection: { sm: "row", xs: "row", md: "row" }, justifyContent: "space-between", alignItems: "center", mt: 1, }} >
            <Box sx={{ display: "flex", alignItems: "center" }} >
              <Typography sx={{
                color: theme.palette.text.secondary, fontWeight: "700", textAlign: "start", width: { sm: "100%", xs: "auto", md: "auto" },
                fontSize: { xs: (winSize.screenSize.width > 500 ? ".65rem" : ".8rem"), sm: ((winSize.screenSize.width < 800 && winSize.screenSize.width > 700) ? ".6rem" : ".7rem"), md: ".75rem" }
              }}>
                M.R.P&nbsp;
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: { sm: "100%", md: "100%", xs: "80%" }, justifyContent: { md: "space-between", sm: "space-between", xs: "space-between" }, alignItems: { xs: "center" } }} >
              <Typography
                sx={{
                  textDecoration: "line-through",
                  fontSize: { xs: (winSize.screenSize.width > 500 ? ".65rem" : ".8rem"), sm: ((winSize.screenSize.width < 800 && winSize.screenSize.width > 700) ? ".6rem" : ".7rem"), md: ".75rem" },
                  fontWeight: "500",
                  color:theme.palette.text.secondary
                }}
              >
                {originalPrice}
              </Typography>
              <Typography sx={{
                fontSize: { md: "1rem", xs: "1rem", sm: "1rem" },
                fontWeight: "700"
              }} >
                &#8377; {Math.round(Number(partnerSellingPrice))}
              </Typography>
            </Box>
          </Box>
          {showCartAdjustment ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={minus}
                alt="Minus Icon"
                style={{
                  width: "30px",
                  height: "auto",
                  marginRight: "27px",
                  cursor: "pointer",
                }}
                onClick={handleDecrementSize}
              />
              <Typography
                variant="body1"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  padding: "5px 10px",
                  borderRadius: "5px",
                  border: "1px solid",
                  borderColor: theme.palette.extra.cartBorderColor,

                }}
              >
                {cart}
              </Typography>
              <img
                src={plus}
                alt="Plus Icon"
                style={{
                  width: "30px",
                  height: "auto",
                  marginLeft: "27px",
                  cursor: "pointer",
                }}
                onClick={handleIncrementSize}
              />
            </Box>
          ) : (
            <Button

              style={{
                backgroundColor: theme.palette.extra.primary,
                color: theme.palette.primary.contrastText,
                width: "100%",
                overflow: "hidden",
                height: "40px",
              }}
              onClick={handleNavigation}
            >
              Add to Cart
            </Button>
          )}
        </Box>
      </Box>
    );
  else {
    return (
      <Box
        sx={{
          width: { xs: "45.95%", sm: "20%", md: "20%" },
          maxWidth: { xs: "169px", sm: "auto" },
          height: "434px",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    )
  }
}

export default AllProductsCard;
