import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CardActionArea, TextField, IconButton, Typography, Pagination, Stack, Skeleton } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import useScreenSize from '../../../../utils/ScreenWidthHight';
import { width } from '@mui/system';

const Category = ({ data, index, onClick, isSkeleton = false }) => {
    const theme = useTheme();

    const winSize = useScreenSize();

    const cardWidth = useMemo(() => {
        let cardWidth = winSize.getWidthForGiveInput(5);
        if (cardWidth <= 270) {
            cardWidth = 270;
        }
        if (winSize.screenSize.width / 2 < cardWidth + 50) {
            cardWidth = winSize.screenSize.width / 2 - 30;
        }
        return cardWidth + "px";
    }, [winSize.screenSize.width]);


    if (!isSkeleton)
        return (

            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: { sm: cardWidth, xs: "156px", }, height: { xs: "150px", sm: "unset" }, }} onClick={onClick} >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid " + theme.palette.extra.primary,
                    width: { md: "300px", xs: "156px", },
                    height: { xs: "auto", sm: "297px" },
                    borderRadius: "20px",
                    "&:hover": {
                        cursor: "pointer"
                    }
                }}>
                    <Typography
                        sx={{
                            width: { xs: "150px", sm: "175px", md: "200px" },
                            maxWidth: "100%",
                            marginLeft: "10px",
                            marginTop: "15px",
                            fontWeight: "bold",
                            '@media (max-width: 600px)': {

                                marginLeft: "6px"
                            },
                        }}
                    >
                        {data.label.slice(0, 15)}
                    </Typography>
                    <img
                        src={data.image+"?q=10"}
                        alt="category"
                        style={{
                            width: (winSize.screenSize.width < 600 ? "150px" : "380px"),
                            height: (winSize.screenSize.width < 600 ? "80px" : "220px"),
                            // maxHeight: "100%",
                            maxWidth: "100%",
                            objectFit: "contain"

                        }}
                    />
                </Box>
            </Box>

        );
    else {
        return (<Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: cardWidth }} onClick={onClick} >
            <Box sx={{
                display: "flex",
                flexDirection: "column",

                width: "300px", height: "297px",
                borderRadius: "20px",
                "&:hover": {
                    cursor: "pointer"
                }
            }}>
                <Skeleton
                    variant='rectangular'
                    sx={{ width: cardWidth, height: "100%" }}
                />
            </Box>
        </Box>
        )
    }
}

export default Category; 